import React from "react";
import styled from "styled-components";
import DocumentationSection from "../../components/docs/DocumentationSection";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

interface Props {
  location: RouterLocation;
}

// Create space between list item markers and content - was too tight
const FormattedListItem = styled.li`
  padding-left: 8px;
`;

function RateLimitsPage({ location }: Props) {
  const title = "Rate limits";
  const description = "Learn about rate limits for the Merge API";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />

      <DocumentationSection title="Overview">
        <p>
          API requests to Merge&apos;s Unified API are rate limited to the following{" "}
          <strong style={{ fontWeight: 600 }}>per linked account</strong> (as identified by the{" "}
          <code>account_token</code> attached to the request):
        </p>
        <ul>
          <FormattedListItem>Launch: 100 / minute</FormattedListItem>
          <FormattedListItem>Professional: 400 / minute</FormattedListItem>
          <FormattedListItem>Enterprise: 600 / minute</FormattedListItem>
        </ul>

        <p>
          For example, if you have 10 customers, the rate limits above would apply to each one
          separately.
        </p>

        <p>
          Some endpoints may have different rate limits as specified throughout the API reference.
        </p>
      </DocumentationSection>

      <CrossGuideLinks location={location} style={{ marginTop: "12px " }} />
    </StaticPageContainer>
  );
}

export default RateLimitsPage;
